import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import computer from "../../../assets/img/computer.png";
import CommentsCarousel from "components/FrontPage/CommentsCarousel/CommentsCarousel";

const CommentsComponent = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");
    const isSmallHeight = useMediaQuery("(min-height:650px)");

    return (
        <Box
            width={"100%"}
            minHeight={"60vh"}
            height={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"20vh"}
        >
            <Box
                width={"40%"}
                height={"100%"}
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}
                // sx={{ border: "1px solid red" }}
            >
                <img src={computer} alt="computer" />
            </Box>
            <Box
                width={isNonMinMobileScreen ? "60%" : "40%"}
                minHeight={"100%"}
                height={"auto"}
                display={"flex"}
                flexDirection={"column"}
                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
            >
                <Box
                    width={"100%"}
                    height={"10vh"}
                    display={"flex"}
                    justifyContent={"left"}
                    alignItems={"center"}
                    paddingLeft={"100px"}
                    marginTop={"4vh"}
                    marginBottom={"4vh"}
                >
                    <Typography variant="h1">
                        <span style={{ color: palette.secondary.main }}>Opinie </span>klientów
                    </Typography>
                </Box>
                <Box
                    width={"100%"}
                    height={"50vh"}
                    display={"flex"}
                    paddingLeft={"20%"}
                    // justifyContent={"start"}
                    alignItems={"center"}
                >
                    <CommentsCarousel />
                </Box>
            </Box>
        </Box>
    );
};

export default CommentsComponent;
