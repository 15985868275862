import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import ShadowBox from "components/Interface/ShadowBox";

import policyData from "data/policyData";

const PolicyPage = () => {
    const theme = useTheme();
    const palette = theme.palette;

    const location = useLocation();

    useEffect(() => {
        // Sprawdzenie, czy stan został przekazany
        if (location.state?.scrollToStart) {
            const element = document.getElementById("start");
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.state]);

    return (
        <Box width={"100%"} height={"auto"} display={"flex"} flexDirection={"column"} padding={"10px"}>
            <div id="start" />
            <ShadowBox
                headerContent={
                    <Typography variant="h1">
                        Polityka <span style={{ color: palette.secondary.main }}>prywatności</span>
                    </Typography>
                }
            >
                <ol>
                    {policyData.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                width={"100%"}
                                height={"auto"}
                                display={"flex"}
                                flexDirection={"column"}
                                marginBottom={"20px"}
                            >
                                <li>
                                    <Typography variant="h4" marginBottom={"5px"} color={palette.primary.main}>
                                        {item.title}
                                    </Typography>
                                </li>
                                <ol>
                                    {item.points.map((point, pointIndex) => (
                                        <li key={pointIndex}>
                                            <Typography variant="h5">{point.text}</Typography>
                                            {point.subsection && (
                                                <ol type="a">
                                                    {point.subsection.map((sub, subIndex) => (
                                                        <li key={subIndex}>
                                                            <Typography variant="h6">{sub.text}</Typography>
                                                        </li>
                                                    ))}
                                                </ol>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </Box>
                        );
                    })}
                </ol>
            </ShadowBox>
        </Box>
    );
};

export default PolicyPage;
