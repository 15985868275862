import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import Lottie from "lottie-react";

import calculatorData from "data/calculatorData";
import desktopLottie from "../../assets/lottie/desktop.json";
import "./CalculatorStyles.scss";

const CalculatorCard = ({
    data, //dane
    rowNumber, //aktualna ilośc sekcji
    setSectionsToShow, //pokazujemy kolejną sekcje
    handleCalculate, //funckja do liczenia ceny
    ////
    singleChoice, //pojednyczy wybór
    setSectionType, // Funkcja do wyboru WER czy MOBILE
    setSingleChoice, // funkcja do pojedynczego wyboru
    clearValue, // zmienna do czyszczenia kalkulatora
}) => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const [picked, setPicked] = useState(null);

    const handlePicked = () => {
        setPicked((prev) => (prev === null ? true : !prev));
        setSingleChoice(data.title);
        setSectionsToShow(rowNumber + 1);
        if (setSectionType) {
            setSectionType(data.selectOption);
        }
    };

    useEffect(() => {
        if (handleCalculate) {
            if (picked === true) {
                handleCalculate((prev) => prev + data.price);
            } else if (picked === false) {
                handleCalculate((prev) => prev - data.price);
            }
        }
    }, [picked]);

    useEffect(() => {
        setPicked(null);
    }, [clearValue]);

    //FUNKCJA DO OBSŁUGI WYBORU POJEDYNCZEJ KARTY Z SEKCJI
    useEffect(() => {
        if (singleChoice && singleChoice !== data.title && picked === true) {
            setPicked((prev) => (prev === null ? true : !prev));
        }
    }, [singleChoice]);

    return (
        <Box
            width={isNonMinMobileScreen ? "250px" : "100px"}
            height={isNonMinMobileScreen ? "250px" : "100px"}
            sx={{
                border: picked ? `4px solid ${palette.secondary.main}` : `2px solid white`,
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "16px",
                cursor: "pointer",
                "&:hover": {
                    border: `2px solid ${palette.secondary.main}`,
                },
                "&:active": {
                    transform: "translateY(4px)",
                },
            }}
            display={"flex"}
            flexDirection={"column"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => {
                handlePicked();
            }}
        >
            <Typography
                variant={isNonMinMobileScreen ? "h3" : "p"}
                marginBottom={isNonMinMobileScreen ? "20px" : "5px"}
                textAlign={"center"}
            >
                {data.title}
            </Typography>
            <img
                src={data.icon}
                alt="text"
                height={isNonMinMobileScreen ? "130px" : "50px"}
                width={isNonMinMobileScreen ? "130px" : "50px"}
            />
        </Box>
    );
};

const CalculatorRow = ({
    data,
    rowNumber, // numer sekcji
    handleCalculate, // funkcja do onliczania ceny
    setSectionsToShow, // funckja do pokazywania nowych skecji

    ////
    isSingleChoice, // zmienna do pokazywani
    setSectionType, // WEB OR MOBILE
    clearValue, // zmienna do czyszczenia kalkulatora
    ////
}) => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");
    const [singleChoice, setSingleChoice] = useState(null);

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignContent={"center"}
            padding={isNonMinMobileScreen ? "30px" : "0px"}
            marginBottom={"5vh"}
        >
            <Box width={"100%"} marginBottom={"3vh"} textAlign={"center"}>
                <Typography variant={isNonMinMobileScreen ? "h3" : "h4"}>{data.question}</Typography>
            </Box>
            <Box
                width={"100%"}
                display={"flex"}
                flexWrap={"wrap"}
                gap={"20px"}
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
            >
                {data.options.map((item, index) => (
                    <CalculatorCard
                        data={item} //dane
                        rowNumber={rowNumber} //aktualna ilośc sekcji
                        setSectionsToShow={setSectionsToShow ? setSectionsToShow : () => {}} //pokazujemy kolejną sekcje
                        handleCalculate={handleCalculate} //funkcja do liczenia ceny
                        ////
                        setSectionType={setSectionType} //WEB OR MOBILE
                        singleChoice={isSingleChoice ? singleChoice : null} //ustawianie pojedyczego wyboru
                        setSingleChoice={isSingleChoice ? setSingleChoice : () => {}}
                        clearValue={clearValue ? clearValue : null} // zmienna do czyszczenia kalkulatora
                    />
                ))}
            </Box>
        </Box>
    );
};

const SelectedComponent = ({ setShowButton, handleCalculate, clearValue }) => {
    const [sectionsToShow, setSectionsToShow] = useState(0);
    const [sectionType, setSectionType] = useState(null);

    const dataToDisplay = sectionType === "web" ? calculatorData.web : calculatorData.mobile;

    // resetujemy kalkulator
    useEffect(() => {
        setSectionsToShow(0); //od nowa okazujemy sekcje
        handleCalculate(0); // liczymy od zera
        setShowButton(false); // chowamy przyciski
        setSectionType(null); // od nowa wybieramy WEB OR MOBILE
    }, [clearValue]);

    //Funckja do pokazywania przycisków na koniec
    useEffect(() => {
        if (dataToDisplay.length + 1 === sectionsToShow) {
            setShowButton(true);
        }
    }, [sectionsToShow]);

    return (
        <Box>
            <CalculatorRow
                data={calculatorData.first[0]} // dane
                rowNumber={0} //aktualna ilośc sekcji
                clearValue={clearValue} // zmienna do resetu kalkulatora
                ////
                isSingleChoice={true} // wybieramy tylko jedną karte
                setSectionsToShow={setSectionsToShow}
                setSectionType={setSectionType}
                ////
                marginBottom="10vh"
            />

            {dataToDisplay.slice(0, sectionsToShow).map((item, index) => (
                <Box key={index} className="fade-in">
                    <CalculatorRow
                        data={item}
                        rowNumber={index + 1} //aktualna ilośc sekcji
                        setSectionsToShow={setSectionsToShow} // funkcja do pokazywania koljnych sekcji
                        handleCalculate={handleCalculate} // funckja do liczenia ceny
                        // handleShowButtons={handleShowButtons}
                        // setShowButtons={setShowButtons} // Pokazujemy przyciski na koniec
                        ////
                        isSingleChoice={sectionType === "web" && index === 0 ? true : null} // dla pierwszego elementu web jest pojedynczy wybór
                        ////
                        marginBottom="10vh"
                    />
                </Box>
            ))}
        </Box>
    );
};

const CalculatorComponent = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const navigate = useNavigate();
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");
    const isNonMidMobileScreen = useMediaQuery("(min-width:1000px)");

    const [showButton, setShowButton] = useState(false);
    const [calculate, setCalculate] = useState(false);

    const [calculatePrice, setCalculatePrice] = useState(0);
    const [clear, setClear] = useState(false);

    const sectionRef = useRef(null);

    const handleClear = () => {
        setClear((prev) => !prev);
        setCalculate(false);
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box
            width={"100%"}
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignContent={"center"}
        >
            <Box
                width={isNonMinMobileScreen ? "90vw" : "100%"}
                minHeight={"60vh"}
                marginLeft={"auto"}
                marginRight={"auto"}
                marginTop={"10vh"}
                marginBottom={"10vh"}
                display={"flex"}
                flexDirection={"column"}
                padding={isNonMinMobileScreen ? "30px" : "5px"}
                ref={sectionRef}
                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
            >
                <Typography
                    variant="h1"
                    marginTop={"5vh"}
                    marginBottom={"5vh"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                >
                    <span style={{ color: palette.secondary.main }}>Kalkulator</span>
                </Typography>
                <SelectedComponent
                    handleCalculate={setCalculatePrice}
                    setShowButton={setShowButton}
                    clearValue={clear}
                />

                {showButton ? (
                    <Box
                        width={isNonMinMobileScreen ? "90%" : "100%"}
                        height={"10vh"}
                        display={"flex"}
                        alignSelf={"center"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginBottom={"10vh"}
                        className="fade-in"
                        padding={"10px"}
                    >
                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={isNonMinMobileScreen ? "206px" : "150px"}
                            height={"46px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={handleClear}
                        >
                            <Typography
                                variant={isNonMinMobileScreen ? "h5" : "p"}
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Zresetuj obliczenia
                            </Typography>
                        </Box>

                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={isNonMinMobileScreen ? "206px" : "80px"}
                            height={"46px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => {
                                setCalculate(true);
                            }}
                        >
                            <Typography
                                variant={isNonMinMobileScreen ? "h5" : "p"}
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                Oblicz
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}

                {calculate ? (
                    <Box
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignContent={"center"}
                        alignItems={"center"}
                        marginBottom={"5vh"}
                        className="fade-in"
                        marginLeft={"auto"}
                        marginRight={"auto"}
                    >
                        {isNonMinMobileScreen ? (
                            <Box
                                width={isNonMidMobileScreen ? "70%" : "100%"}
                                height={"auto"}
                                minHeight={"50vh"}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                sx={{
                                    border: `2px solid ${palette.secondary.main}`,
                                    borderRadius: "16px",
                                }}
                            >
                                <Box
                                    width={"50%"}
                                    height={"50vh"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                >
                                    <Box width={"60%"} height={"auto"} display={"flex"} alignSelf={"center"}>
                                        <Lottie animationData={desktopLottie} loop={true} />
                                    </Box>
                                    <Typography variant="h3" textAlign={"center"}>
                                        Możemy zrealizować twój <br /> pomysł już od{" "}
                                        <span style={{ color: "#7D97F4" }}>{calculatePrice} zł</span>
                                    </Typography>
                                </Box>
                                <Box width={"1px"} height="40vh" border="1px solid black"></Box>
                                <Box
                                    width={"50%"}
                                    height={"50vh"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    gap={"5vh"}
                                    paddingLeft={"20px"}
                                    paddingRight={"20px"}
                                >
                                    <Typography variant="h2" textAlign={"center"}>
                                        Porozmawiajmy!
                                    </Typography>

                                    <Box
                                        borderRadius={"16px"}
                                        sx={{
                                            background: "#1D3557",
                                            boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                            cursor: "pointer",
                                            "&:hover": {
                                                background: `#0C6CE9`,
                                            },
                                        }}
                                        width={"220px"}
                                        height={"46px"}
                                        textAlign={"center"}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        onClick={() => {
                                            navigate("/", { state: { scrollToForm: true } });
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            textAlign={"left"}
                                            fontWeight={"bold"}
                                            borderRadius={"16px"}
                                            color={"white"}
                                        >
                                            Formularz kontaktowy
                                        </Typography>
                                    </Box>

                                    <Typography variant="p" color={"#828282"}>
                                        Podana wycena online może różnić się od ostatecznej wyceny.
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                width={"100%"}
                                height={"auto"}
                                minHeight={"50vh"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                paddingLeft={"20px"}
                                paddingRight={"20px"}
                                paddingBottom={"20px"}
                                gap={"20px"}
                                sx={{
                                    border: `2px solid ${palette.secondary.main}`,
                                    borderRadius: "16px",
                                }}
                            >
                                <Box width={"60%"} height={"auto"} display={"flex"} alignSelf={"center"}>
                                    <Lottie animationData={desktopLottie} loop={true} />
                                </Box>
                                <Typography variant="h3" textAlign={"center"}>
                                    Możemy zrealizować twój <br /> pomysł już od{" "}
                                    <span style={{ color: "#7D97F4" }}>{calculatePrice} zł</span>
                                </Typography>

                                <Box
                                    borderRadius={"16px"}
                                    sx={{
                                        background: "#1D3557",
                                        boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                        cursor: "pointer",
                                        "&:hover": {
                                            background: `#0C6CE9`,
                                        },
                                    }}
                                    width={"220px"}
                                    height={"46px"}
                                    textAlign={"center"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    onClick={() => {
                                        navigate("/", { state: { scrollToForm: true } });
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        textAlign={"left"}
                                        fontWeight={"bold"}
                                        borderRadius={"16px"}
                                        color={"white"}
                                    >
                                        Formularz kontaktowy
                                    </Typography>
                                </Box>

                                <Typography variant="p" color={"#828282"}>
                                    Podana wycena online może różnić się od ostatecznej wyceny.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default CalculatorComponent;
