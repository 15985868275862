import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { themeSettings } from "theme";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material";

import Header from "components/Header/Header";
import Footer from "components/Footer/FooterComponent";
import Cookie from "components/CookieComponent/Cookie";

import FrontPage from "pages/FrontPage";
import PortfolioPage from "pages/PortfolioPage";
import CalculatorPage from "pages/CalculatorPage";
import PolicyPage from "pages/PolicyPage";
const theme = createTheme(themeSettings("light"));

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Header />
                    <ScrollToTop />
                    <Cookie />
                    <Routes>
                        <Route path="/" element={<FrontPage />}></Route>
                        <Route path="/portfolio" element={<PortfolioPage />}></Route>
                        <Route path="/calculator" element={<CalculatorPage />}></Route>
                        <Route path="/private-policy" element={<PolicyPage />}></Route>
                    </Routes>
                    <Footer />
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
