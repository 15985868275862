import alert from "../assets/calculator/alert.png";
import android from "../assets/calculator/android.png";
import aparat from "../assets/calculator/aparat.png";
import apple from "../assets/calculator/apple.png";
import app from "../assets/calculator/application.png";
import auth from "../assets/calculator/auth.png";
import chat from "../assets/calculator/chat.png";
import cms from "../assets/calculator/cms.png";
import config from "../assets/calculator/config.png";
import data from "../assets/calculator/data.png";
import diff from "../assets/calculator/diff.png";
import gps from "../assets/calculator/gps.png";
import kiosk from "../assets/calculator/kiosk.png";
import map from "../assets/calculator/map.png";
import marketplace from "../assets/calculator/marketplace.png";
import mobile from "../assets/calculator/mobile.png";
import payment from "../assets/calculator/payment.png";
import player from "../assets/calculator/player.png";
import social from "../assets/calculator/social.png";
import question from "../assets/calculator/question.png";
import website from "../assets/calculator/website.png";
import web from "../assets/calculator/web1.png";

const calculatorData = {
    first: [
        {
            question: "Jakiego rodzaju aplikacji potrzebujesz?",
            options: [
                {
                    title: "Aplikacja webowa",
                    icon: web,
                    price: 0,
                    selectOption: "web",
                },
                {
                    title: "Aplikacja mobilna",
                    icon: mobile,
                    price: 0,
                    selectOption: "mobile",
                },
            ],
        },
    ],
    mobile: [
        {
            question: "Na jakich systemach chcesz aby aplikacja działała?",
            options: [
                {
                    title: "iOS",
                    icon: apple,
                    price: 2000,
                },
                {
                    title: "Android",
                    icon: android,
                    price: 2000,
                },
                {
                    title: "Inny",
                    icon: question,
                    price: 4000,
                },
            ],
        },
        {
            question: "Jakimi cechami odnzacza się aplikacja?",
            options: [
                {
                    title: "Serwis społecznościowy",
                    icon: social,
                    price: 4000,
                },
                {
                    title: "Narzędzie",
                    icon: config,
                    price: 4000,
                },
                {
                    title: "Marketplace",
                    icon: marketplace,
                    price: 4000,
                },
                {
                    title: "Kiosk",
                    icon: kiosk,
                    price: 4000,
                },
                {
                    title: "CMS",
                    icon: cms,
                    price: 4000,
                },
                {
                    title: "Inne",
                    icon: diff,
                    price: 0,
                },
            ],
        },
        {
            question: "Wybierz główne funkcje które ma zawierać aplikacja",
            options: [
                {
                    title: "Autoryzacja",
                    icon: auth,
                    price: 500,
                },
                {
                    title: "Płatności",
                    icon: payment,
                    price: 1500,
                },
                {
                    title: "Mapy",
                    icon: map,
                    price: 1000,
                },
                {
                    title: "GPS",
                    icon: gps,
                    price: 7000,
                },
                {
                    title: "Zbiór danych",
                    icon: data,
                    price: 800,
                },
                {
                    title: "Chat",
                    icon: chat,
                    price: 3000,
                },
                {
                    title: "Video player",
                    icon: player,
                    price: 1000,
                },
                {
                    title: "Powiadomienia",
                    icon: alert,
                    price: 1000,
                },
                {
                    title: "Aparat",
                    icon: aparat,
                    price: 1500,
                },
            ],
        },
    ],
    web: [
        {
            question:
                "Czy strona ma pełnić funkcję prostej, łatwej w aktualizacji wizytówki czy ma być bardziej profesjonalna, dynamiczna i z możliwością rozbudowy?",
            options: [
                {
                    title: "Profesjonalna",
                    icon: app,
                    price: 4000,
                },
                {
                    title: "Wizytówka",
                    icon: website,
                    price: 500,
                },
            ],
        },
        {
            question: "Jakimi cechami odnzacza się strona?",
            options: [
                {
                    title: "Serwis społecznościowy",
                    icon: social,
                    price: 4000,
                },
                {
                    title: "Narzędzie",
                    icon: config,
                    price: 4000,
                },
                {
                    title: "Marketplace",
                    icon: marketplace,
                    price: 4000,
                },
                {
                    title: "Kiosk",
                    icon: kiosk,
                    price: 4000,
                },
                {
                    title: "CMS",
                    icon: cms,
                    price: 4000,
                },
                {
                    title: "Inne",
                    icon: diff,
                    price: 0,
                },
            ],
        },
        {
            question: "Wybierz główne funkcje które ma zawierać aplikacja",
            options: [
                {
                    title: "Autoryzacja",
                    icon: auth,
                    price: 500,
                },
                {
                    title: "Płatności",
                    icon: payment,
                    price: 500,
                },
                {
                    title: "Mapy",
                    icon: map,
                    price: 500,
                },
                {
                    title: "Zbiór danych",
                    icon: data,
                    price: 500,
                },
                {
                    title: "Chat",
                    icon: chat,
                    price: 500,
                },
                {
                    title: "Video player",
                    icon: player,
                    price: 500,
                },
                {
                    title: "Powiadomienia",
                    icon: alert,
                    price: 500,
                },
            ],
        },
    ],
};

export default calculatorData;
