import findBetLogo from "../assets/img/findBet-logo.png";
import roomTourLogo from "../assets/img/roomTour.png";
import stars from "../assets/img/stars.png";
// import profile from "../assets/img/profile.jpg";
const comments = [
    {
        stars: stars,
        image: findBetLogo,
        name: "",
        company: "FindBET",
        text: "Ekspresowe wykonanie MVP dzięki któremu mogliśmy niskobudżetowo zweryfikować nasze założenia biznesowe. Polecam współpracę z tym zespołem! To jeszcze nie koniec naszej wspólnej przygody.",
    },
    {
        stars: stars,
        image: roomTourLogo,
        name: "",
        company: "Room Tour",
        text: "Potrzebowaliśmy wewnętrznego narzędzia do zarządzania stanami magazynowymi. Otrzymaliśmy działający produkt wraz ze wsparciem podczas wdrażania. Wszystko zostało skompletowane terminowo i na bardzo wysokim poziomie.",
    },
];

export default comments;
