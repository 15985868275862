import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Button = ({ text, url, width, height, variant }) => {
    const navigate = useNavigate();
    const [customStyle, setCustomStyle] = useState({
        width: "128px",
        height: "38px",
        variant: "p",
    });

    useEffect(() => {
        setCustomStyle({
            width: width || "128px",
            height: height || "38px",
            variant: variant || "p",
        });
    }, []);

    return (
        <Box
            borderRadius={"16px"}
            sx={{
                background: "#1D3557",
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
                "&:hover": {
                    background: `#0C6CE9`,
                },
            }}
            width={customStyle.width}
            height={customStyle.height}
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => {
                navigate(url, { state: { scrollToStart: true } });
            }}
        >
            <Typography
                variant={customStyle.variant}
                textAlign={"left"}
                fontWeight={"bold"}
                borderRadius={"16px"}
                color={"white"}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default Button;
