import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import FormContainer from "components/ContactComponent/FormContainer/FormContainer";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt, FaInstagram } from "react-icons/fa";
import ShadowBox from "components/Interface/ShadowBox";

const ContactComponentMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            width={isNonMinMobileScreen ? "90%" : "100%"}
            height={"auto"}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginBottom={"10vh"}
            sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
        >
            <Box
                width={"100%"}
                height={"auto"}
                minHeight={"10vh"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                marginBottom={"3vh"}
                marginTop={"10vh"}
                padding={isNonMinMobileScreen ? "30px" : "10px"}
            >
                <Typography variant="h1">
                    <span style={{ color: palette.secondary.main }}>Kontakt</span>
                </Typography>
                <Typography variant="h5" fontWeight={"bold"} marginTop={"20px"}>
                    Jeżeli masz jakiekolwiek pytania, potrzebujesz wyceny, lub chcesz porozmawiać o swoim projekcie,
                    jesteśmy do Twojej dyspozycji.
                </Typography>
            </Box>
            <Box padding={"20px"}>
                <FormContainer />
                <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} marginTop={"4vh"} gap={"20px"}>
                    <Box
                        sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                        height={"10vh"}
                        width="fit-content"
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"10px"}
                        padding={"15px"}
                        paddingY={"10px"}
                    >
                        <MdOutlineMailOutline color={palette.secondary.main} size={"37px"} />{" "}
                        <Typography variant="h6">antoni.pochopien@byteitright.pl</Typography>
                    </Box>
                    <Box
                        sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                        height={"10vh"}
                        width="fit-content"
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"10px"}
                        padding={"15px"}
                        paddingY={"10px"}
                    >
                        <MdOutlineMailOutline color={palette.secondary.main} size={"37px"} />{" "}
                        <Typography variant="h6">maciej.kijowski@byteitright.pl</Typography>
                    </Box>
                    <Box
                        sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                        height={"10vh"}
                        width="fit-content"
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"20px"}
                        padding={"15px"}
                        paddingY={"10px"}
                    >
                        <FaPhoneAlt color={palette.secondary.main} size={"30px"} />{" "}
                        <Typography variant="h6">+48 792 450 474</Typography>
                    </Box>
                    <Box
                        sx={{
                            boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "16px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            window.location.href = "https://www.instagram.com/byteitright/";
                        }}
                        height={"10vh"}
                        width="fit-content"
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"20px"}
                        padding={"15px"}
                        paddingY={"10px"}
                    >
                        <FaInstagram color={palette.secondary.main} size={"37px"} />{" "}
                        <Typography variant="h6">instagram.com/byteitright</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ContactComponentMobile;
