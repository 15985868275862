import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import androidLogo from "../../assets/logos/adroid.png";
import awslogo from "../../assets/logos/aws.png";
import flutterLogo from "../../assets/logos/flutter.png";
import firebaseLogo from "../../assets/logos/firebase.png";
import reduxlogo from "../../assets/logos/reduxLogo.png";
import reactLogo from "../../assets/logos/reactLogo.png";
import nodeLogo from "../../assets/logos/nodeLogo.png";
import kotlin from "../../assets/logos/kotlin.png";
import dart from "../../assets/logos/dart.png";
import wordpress from "../../assets/logos/wordpress.png";

const LogoWaterfallMobile = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const logoArr = [
        {
            logo: androidLogo,
            x: 10,
            delay: 0,
            name: `Android-logo`,
            width: "150px",
        },
        {
            logo: kotlin,
            x: 70,
            delay: 0.3,
            name: `kotlin-logo`,
            width: "80px",
        },
        {
            logo: dart,
            x: 20,
            delay: 5.4,
            name: `dart-logo`,
            width: "80px",
        },
        {
            logo: wordpress,
            x: 55,
            delay: 5.8,
            name: `wordpress-logo`,
            width: "150px",
        },
        {
            logo: nodeLogo,
            x: 20,
            delay: 3.2,
            name: `node-logo`,
            width: "100px",
        },
        {
            logo: reactLogo,
            x: 50,
            delay: 1.5,
            name: `react-logo`,
            width: "100px",
        },
        {
            logo: flutterLogo,
            x: 10,
            delay: 2,
            name: `flutter-logo`,
            width: "80px",
        },
        {
            logo: awslogo,
            x: 60,
            delay: 2.9,
            name: `aws-logo`,
            width: "120px",
        },
        {
            logo: firebaseLogo,
            x: 10,
            delay: 4.5,
            name: `firebase-logo`,
            width: "150px",
        },
        {
            logo: reduxlogo,
            x: 60,
            delay: 4.2,
            name: `redux-logo`,
            width: "100px",
        },
    ];

    return (
        <Box width={"100vw"} height={"100vh"}>
            <Box className="front-container">
                <Box
                    width={isNonMinMobileScreen ? "70%" : "90%"}
                    display={"flex"}
                    flexDirection={"column"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    gap={"25px"}
                    position={"absolute"}
                    zIndex={"5"}
                >
                    <Typography variant="h1">
                        Tworzymy dedytkowane <span style={{ color: palette.secondary.main }}>aplikacje</span> dla firm.
                    </Typography>
                    <Typography variant={isNonMinMobileScreen ? "h3" : "h4"} position={"relative"} zIndex={"3"}>
                        Tworzymy oprogramowanie, które spełnia najwyższe oczekiwania biznesu, dopasowane do Twoich
                        potrzeb. Pracujemy z najnowszymi technologiami, aby dostarczać rozwiązania, które napędzają Twój
                        sukces.
                    </Typography>
                </Box>
            </Box>
            <Box
                className="floating-images-container-mobile"
                // sx={{ background: "linear-gradient(to bottom, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%)" }}
            >
                {logoArr.map((logoItem, index) => {
                    return (
                        <img
                            key={logoItem.name}
                            src={logoItem.logo}
                            className="floating-image"
                            // onTransitionEnd={handleAnimationEnd}
                            style={{
                                left: `${logoItem.x}%`, // Losowa pozycja dla każdego obrazka
                                animationDelay: `${logoItem.delay}s`, // Losowe opóźnienie animacji dla każdego obrazka
                                width: logoItem.width,
                            }}
                            alt={`Floating ${logoItem.name}`}
                        />
                    );
                })}
                {/* {handleFloatingImg()} */}
            </Box>
        </Box>
    );
};

export default LogoWaterfallMobile;
