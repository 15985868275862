import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { LuCookie } from "react-icons/lu";
const Cookie = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const [isVisible, setIsVisible] = useState(true);
    const isNonMobileScreen = useMediaQuery("(min-width:500px)");

    const handleClick = () => {
        localStorage.setItem("cookiesAccepted", true);
        setIsVisible(false);
    };

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (cookiesAccepted === "true") {
            setIsVisible(false);
        }
    }, []);

    const styleWeb = {
        background: "#ffffff87",
        position: "absolute",
        right: "1vw",
        bottom: "1vw",
    };

    const styleMobile = {
        background: "#ffffff",
        position: "absolute",
        right: "0",
        left: "0",
        marginLeft: "auto",
        marginRight: "auto",
        bottom: "1vw",
    };

    return (
        <Box>
            {isVisible ? (
                <Box
                    width={"350px"}
                    height={"250px"}
                    display={"flex"}
                    alignContent={"center"}
                    justifyContent={"center"}
                    justifyItems={"center"}
                    flexDirection={"column"}
                    gap={"20px"}
                    paddingLeft={"20px"}
                    paddingRight={"20px"}
                    border={`3px solid ${palette.secondary.main}`}
                    borderRadius={"16px"}
                    zIndex={50}
                    position={"absolute"}
                    right={"1vw"}
                    bottom={"1vw"}
                    sx={isNonMobileScreen ? styleWeb : styleMobile}
                >
                    <Box width={"100%"} height={"auto"} display={"flex"} justifyContent={"center"}>
                        <LuCookie size={"4rem"} color={palette.primary.main} />
                    </Box>
                    <Typography variant="h5" textAlign={"center"}>
                        Ta strona używa plików cookie. Korzystając z niej, zgadzasz się na ich użycie.{" "}
                        <a href="/private-policy" color={palette.secondary.main} style={{ textDecoration: "none" }}>
                            Dowiedz się więcej
                        </a>
                        .
                    </Typography>
                    <Box width={"100%"} height={"auto"} display={"flex"} justifyContent={"center"}>
                        <Box
                            borderRadius={"16px"}
                            sx={{
                                background: "#1D3557",
                                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                "&:hover": {
                                    background: `#0C6CE9`,
                                },
                            }}
                            width={"128px"}
                            height={"38px"}
                            textAlign={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={handleClick}
                        >
                            <Typography
                                variant={"h5"}
                                textAlign={"left"}
                                fontWeight={"bold"}
                                borderRadius={"16px"}
                                color={"white"}
                            >
                                OK
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <></>
            )}{" "}
        </Box>
    );
};

export default Cookie;
