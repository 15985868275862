import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt, FaInstagram } from "react-icons/fa";

import FormContainer from "components/ContactComponent/FormContainer/FormContainer";

import ShadowBox from "components/Interface/ShadowBox";

const ContactComponent = () => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");
    const theme = useTheme();
    const palette = theme.palette;

    return (
        <ShadowBox
            headerContent={
                <Typography variant="h1">
                    <span style={{ color: palette.secondary.main }}>Kontakt</span>
                </Typography>
            }
        >
            <Box
                width={"100%"}
                minHeight={"95vh"}
                height={"auto"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                gap={"15%"}
                padding={"5vw"}
            >
                <Box width={"50%"} height={"auto"}>
                    <Box width={"100%"} height={"10vh"} display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} variant="h2" marginBottom={"3vh"}>
                            Porozmawiajmy!
                        </Typography>
                        <Typography variant="h5" marginBottom={"3vh"}>
                            Jeżeli masz jakiekolwiek pytania, potrzebujesz wyceny, lub chcesz porozmawiać o swoim
                            projekcie, jesteśmy do Twojej dyspozycji. Wypełnij poniższy formularz, a nasz zespół
                            skontaktuje się z Tobą tak szybko, jak to możliwe. Niezależnie od tego, czy masz wstępny
                            pomysł, czy już konkretną wizję, chętnie pomożemy Ci w jego realizacji. Jesteśmy tutaj, aby
                            odpowiedzieć na Twoje pytania, doradzić najlepsze rozwiązania i wspólnie znaleźć drogę do
                            sukcesu. Czekamy na Twoją wiadomość!
                        </Typography>
                        <Box display={"flex"} flexDirection={"row"} gap={"5vw"}>
                            <Typography variant="h4">
                                Do usłyszenia, zespół <spam fontWeight="bold">BYTE IT RIGHT!</spam>
                            </Typography>
                        </Box>
                        <Box
                            width={"100%"}
                            height={"auto"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            marginTop={"4vh"}
                            gap={"20px"}
                        >
                            <Box
                                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                                height={"10vh"}
                                width="fit-content"
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"10px"}
                                padding={"15px"}
                                paddingY={isNonMinMobileScreen ? "25px" : "10px"}
                            >
                                <MdOutlineMailOutline color={palette.secondary.main} size={"37px"} />{" "}
                                <Typography variant="h6">antoni.pochopien@byteitright.pl</Typography>
                            </Box>
                            <Box
                                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                                height={"10vh"}
                                width="fit-content"
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"10px"}
                                padding={"15px"}
                                paddingY={isNonMinMobileScreen ? "25px" : "10px"}
                            >
                                <MdOutlineMailOutline color={palette.secondary.main} size={"37px"} />{" "}
                                <Typography variant="h6">maciej.kijowski@byteitright.pl</Typography>
                            </Box>
                            <Box
                                sx={{ boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "16px" }}
                                height={"10vh"}
                                width="fit-content"
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"20px"}
                                padding={"15px"}
                                paddingY={isNonMinMobileScreen ? "25px" : "10px"}
                            >
                                <FaPhoneAlt color={palette.secondary.main} size={"30px"} />{" "}
                                <Typography variant="h6">+48 792 450 474</Typography>
                            </Box>
                            <Box
                                sx={{
                                    boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "16px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    window.location.href = "https://www.instagram.com/byteitright/";
                                }}
                                height={"10vh"}
                                width="fit-content"
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"20px"}
                                paddingX={"15px"}
                                paddingY={isNonMinMobileScreen ? "25px" : "10px"}
                            >
                                <FaInstagram color={palette.secondary.main} size={"37px"} />{" "}
                                <Typography variant="h6">instagram.com/byteitright</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box width={"30%"} height="100%">
                    <FormContainer />
                </Box>
            </Box>
        </ShadowBox>
    );
};

export default ContactComponent;
